.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.SundialDisplay {
  max-height: 80%;
  max-width: 80%;
}
